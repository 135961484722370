<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Detail Rekanan" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">Jenis Rekanan</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Company" :value="data.jenis_rekanan.nama" readonly/>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode" v-model="data.kode" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3 w-full">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama_proyek" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp" v-model="data.telp" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label-placeholder="E-mail" v-model="data.email" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-printer" label-placeholder="Fax" v-model="data.fax" readonly/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label-placeholder="CP" v-model="data.cp" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="No. KTP" v-model="data.no_ktp" readonly/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Jabatan" v-model="data.jabatan" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="NPWP" v-model="data.npwp" readonly/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="NPPKP" v-model="data.nppkp" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat" v-model="data.alamat" readonly/>
          </div>
        </div>

        <vs-divider> Akun Bank</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Bank" v-model="data.bank_rekening" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="No. Rekening" v-model="data.no_rekening" readonly/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="A/N Rekening" v-model="data.an_rekening" readonly/>
          </div>
        </div>

        <vs-divider> User Login</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label-placeholder="Username" autocomplete="new-password" v-model="data.username" readonly/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Tutup</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import _ from 'lodash'

export default {
  name: 'RekananDetail',
  components: {
  },
  props: ['isActive', 'item'],
  watch: {
    isActive (value) {
      if (value === true) {
        const item = _.cloneDeep(this.item)
        item.id_proyeks = _.map(item.details, 'id_proyek')
        this.data = item
      }
    },
    'data.jenis_rekanan' (value) {
      this.data.id_jenis_rekanan = value.id
    }
  },
  mounted () {
    this.getAllProyek()
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      data: {
        jenis_rekanan: {},
        id_proyeks: []
      },
      proyeks: []
    }
  },
  methods: {
    getAllProyek () {
      ProyekRepository.getAll()
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
